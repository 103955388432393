export const environment = {
  production: false,
  debug: true,
  nombre_aplicacion : "Renta Nacional - Asistencia Online",
  filtro_aplicacion : true, 
  _ssp : 'i8eKn8r2GU68xkq2G0$nzcA^',
  api : {
    "uss_" : "U2FsdGVkX19VFauv87eiRIESTeXYjKwyNGRvMFIJ0rg=",
    "pss_" : "U2FsdGVkX1/PqCqdfomRU8afqbTiy3l08q+4sjX4aUc=",
    "ull_" : "https://dev1.prosys.cl/~mvergara/mw_asistencia_rac/web/renta_nacional/",
    "ull_com" : "https://dev1.prosys.cl/~mvergara/mw_asistencia_rac/web/",
  },
  api_2 : {
    "url" : "https://dev.prosys.cl/~cabello/api_asistencias/api/",
    //"url" : "https://mw-asistencia.testing.prosys.cl/api/",
  },
  contactdata: {
    telefono: "600 390 1000"
  },
  telefono: "tel: +56222665466",
  gmaps: {
    token: "AIzaSyDe45OzsTPacG05l8N4cdrRpvbzh4JfSf4",
    options: "&libraries=places",
    refresh: 30
  },
  gcaptcha: "6LenmqUZAAAAACkMkKjlM1xqnsHx0FAKqsb3zmpn", 
};

//6Le5mqUZAAAAAEO_5bFqSl89S895esApOrdvecvp